import moment from "moment";
import {
  PRODUCT_STATUS,
  GREEN,
  GRAY,
  BLUE,
  RED,
  YELLOW,
} from "../../constants/commonConstants";
import CryptoJS from "crypto-js";
import { ROUTES_LABEL } from "../../constants/routesLabels";
import { Buffer } from "buffer";
const pako = require("pako");
const ExcelJS = require("exceljs");
const _ = require("lodash");

export const CentralStockAvailabilityCheckerJson = {
  enabledSalesAreas: [
    "AT100201",
    "BA300201",
    "BE100201",
    "BG300201",
    "CH100201",
    "CS300201",
    "CZ110201",
    "DE100201",
    "DK200201",
    "ES100201",
    "EU100201",
    "EU550201",
    "FI100201",
    "FR100201",
    "GB200201",
    "GR100201",
    "HR300201",
    "HU100201",
    "IE400201",
    "IT100201",
    "LV110201",
    "MK300201",
    "NL200201",
    "NO100201",
    "PL100201",
    "PT100201",
    "RO300201",
    "SE100201",
    "SI300201",
    "SK100201",
    "TR100201",
  ],
  enabledHierarchyCodes: ["SCPCDIMACC", "SCPCDIMAMCNEXL", "SCPCDIMAMCALEN"],
};
// Download Excel
export const downloadExcel = (header: any, data: any, excelName: any) => {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();
  // Add the worksheet to the workbook
  const sheet = workbook.addWorksheet("Sheet1");
  // Style for header row
  sheet.getRow(1).font = {
    bold: true,
  };
  sheet.columns = header;
  for (let i = 0; i < data?.length; i++) {
    sheet.addRow(data[i]);
  }
  downloadExcelFile(workbook, excelName);
};

export const downloadExcelFile = (workbook: any, excelName: any) => {
  workbook.xlsx.writeBuffer().then((data: any) => {
    // Create a Blob from the array
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
    });
    // Create a download URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create a link element and simulate a click to trigger the download
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = excelName;
    anchor.click();
    // Clean up the URL and link
    window.URL.revokeObjectURL(url);
    anchor.remove();
  });
};

export const downloadFile = (data: any, fileName: any, fileType: any) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

// const secretKey = process.env?.REACT_APP_SECRET_PASS_KEY
//   ? process.env?.REACT_APP_SECRET_PASS_KEY
//   : "";

// base64 encryption function
// export const base64Encryption = (key: any) => {
//   const encrptedKey = CryptoJS.AES.encrypt(key, secretKey).toString();
//   return encrptedKey;
// };

// base64 decryption function
// export const base64Decryption = (key: any) => {
//   const bytes = CryptoJS.AES.decrypt(key, secretKey);
//   const decryptedKey = bytes.toString(CryptoJS.enc.Utf8);
//   return decryptedKey;
// };

// Functions to sort nested Data
export const getCellValue = (row: any, key: any) => {
  const keys = key.split("_");
  let value = row;
  keys.forEach((k: any) => {
    if (value && typeof value === "object" && k in value) {
      if (k === "reportScheduleLines") value = value[k][0];
      else value = value[k];
    } else {
      value = null;
    }
  });
  return value;
};

export const sortNestedData = (a: any, b: any, orderBy: any) => {
  let valueA = getCellValue(a, orderBy);
  let valueB = getCellValue(b, orderBy);
  if (valueB > valueA) {
    return -1;
  }
  if (valueB < valueA) {
    return 1;
  }
  return 0;
};

// below 3 functions are used as part of sorting
export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let key = String(orderBy);
  if (key.includes(".")) {
    const value1 = key.split(".").reduce((o: any, i: any) => o?.[i], a);
    const value2 = key.split(".").reduce((o: any, i: any) => o?.[i], b);
    if (value1 != undefined && value2 != undefined) {
      if (value2 < value1) {
        return -1;
      }
      if (value2 > value1) {
        return 1;
      }
      return 0;
    }

    if (value1 == undefined && value2 == undefined) {
      return 0;
    }

    if (value1 == undefined) {
      return -1;
    }
    if (value2 == undefined) {
      return 1;
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: "asc" | "desc",
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function createMenuItems(
  data: any,
  roles: any,
  bgName: any,
  locale: any,
  loginResponse: any,
  categoryResponse: any,
  ecot3: any = ""
) {
  const menuItems = [];
  //For Sony1 home menu
  menuItems.push({
    sectionName: data?.siteSection?.siteSectionMetaData?.name,
    label: data?.siteSection?.siteSectionMetaData?.displayName,
    display: true,
    route: fetchRoute(
      data?.siteSection?.simpleUrlAccessDefinition?.value,
      categoryResponse
    ),
    subMenu: [],
    expanded: false,
    menuType: "mainMenu",
  });
  //looping through all other menus except sony 1 home
  data.siteSection?.siteSection?.forEach((section: any) => {
    if (
      isAccessibleMenu(section, roles, bgName, locale, loginResponse, ecot3) &&
      loginResConfigCheck(
        section?.siteSectionMetaData?.name,
        loginResponse,
        bgName
      )
    ) {
      if (section?.siteSection) {
        let item = {
          sectionName: section?.siteSectionMetaData?.name,
          label: section?.siteSectionMetaData?.displayName,
          display: true,
          route: fetchRoute(
            section?.simpleUrlAccessDefinition?.value
              ? section?.simpleUrlAccessDefinition?.value
              : section?.siteSectionMetaData?.type,
            categoryResponse
          ),
          subMenu: [],
          expanded: false,
          menuType: "mainMenu",
        };
        let subSectionList: any = [];
        //looping through sub menus
        if (section?.siteSection?.length > 0) {
          section?.siteSection?.forEach((subSection: any) => {
            if (
              isAccessibleMenu(
                subSection,
                roles,
                bgName,
                locale,
                loginResponse,
                ecot3
              ) &&
              loginResConfigCheck(
                subSection?.siteSectionMetaData?.name,
                loginResponse,
                bgName
              )
            ) {
              let label = subSection?.siteSectionMetaData?.displayName;
              //fetch display name from category response for all product categories
              if (section?.siteSectionMetaData?.name === "DNET Products") {
                if (
                  subSection?.siteSectionMetaData?.categoryGenericName?.trim() !==
                  ""
                ) {
                  label = fetchDisplayName(
                    subSection?.siteSectionMetaData?.categoryGenericName,
                    categoryResponse
                  );
                }
              }
              if (label) {
                subSectionList.push({
                  sectionName: subSection?.siteSectionMetaData?.name,
                  label: label,
                  display: true,
                  route: fetchRoute(
                    subSection?.simpleUrlAccessDefinition?.value
                      ? subSection?.simpleUrlAccessDefinition?.value
                      : subSection?.siteSectionMetaData?.type,
                    categoryResponse
                  ),
                  subMenu: [],
                  genericName:
                    subSection?.siteSectionMetaData?.categoryGenericName,
                  expanded: false,
                  menuType: "mainMenu",
                });
              }
            }
          });
        } else {
          if (
            isAccessibleMenu(
              section?.siteSection,
              roles,
              bgName,
              locale,
              loginResponse,
              ecot3
            ) &&
            loginResConfigCheck(
              section?.siteSection?.siteSectionMetaData?.name,
              loginResponse,
              bgName
            )
          ) {
            subSectionList.push({
              sectionName: section?.siteSection?.siteSectionMetaData?.name,
              label: section?.siteSection?.siteSectionMetaData?.displayName,
              display: true,
              route: fetchRoute(
                section?.siteSection?.simpleUrlAccessDefinition?.value
                  ? section?.siteSection?.simpleUrlAccessDefinition?.value
                  : section?.siteSection?.siteSectionMetaData?.type,
                categoryResponse
              ),
              subMenu: [],
              expanded: false,
              menuType: "mainMenu",
            });
          }
        }
        item.subMenu = subSectionList;
        menuItems.push(item);
      } else if (
        section?.siteSectionMetaData?.displayInMainNavigation === "true"
      ) {
        menuItems.push({
          sectionName: section?.siteSectionMetaData?.name,
          label: section?.siteSectionMetaData?.displayName,
          display: true,
          route: fetchRoute(
            section?.simpleUrlAccessDefinition?.value
              ? section?.simpleUrlAccessDefinition?.value
              : section?.siteSectionMetaData?.type,
            categoryResponse
          ),
          subMenu: [],
          expanded: false,
          menuType: "mainMenu",
        });
      } else if (
        section?.siteSectionMetaData?.displayInMainNavigation === "false"
      ) {
        menuItems.push({
          sectionName: section?.siteSectionMetaData?.name,
          label: section?.siteSectionMetaData?.displayName,
          display: true,
          route: fetchRoute(
            section?.simpleUrlAccessDefinition?.value
              ? section?.simpleUrlAccessDefinition?.value
              : section?.siteSectionMetaData?.type,
            categoryResponse
          ),
          subMenu: [],
          expanded: false,
          menuType: section?.siteSectionMetaData?.mainContentCategories
            ? section?.siteSectionMetaData?.mainContentCategories
                ?.mainContentCategory
            : "",
          type: section?.siteSectionMetaData?.type,
        });
      }
    }
  });
  return menuItems;
}

function fetchDisplayName(genericName: any, categoryRes: any) {
  for (let i = 0; i < categoryRes?.children?.length; i++) {
    let name = categoryRes?.children[i]?.name?.replaceAll(/[^A-Za-z0-9]/g, "");
    if (name?.toLowerCase() === genericName?.toLowerCase()) {
      return categoryRes?.children[i]?.displayName;
    }
  }
}

function fetchRoute(data: any, categoryRes: any) {
  if (data) {
    switch (data) {
      case "/site/AccessHomePage.action":
        return ROUTES_LABEL.SONY_HOMEPAGE;
      case "/product/Catalog.action":
        return ROUTES_LABEL.PRODUCT_RANGE;
      case "/view/ShowCategory.action?item=newproducts":
        return ROUTES_LABEL.NEW_PRODUCTS;
      case "/view/ShowCategory.action?item=Camerascamcorders":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("Camerascamcorders", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=Homeaudio":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("Homeaudio", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=TVHomeCinema":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("TVHomeCinema", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=SonyMobile":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("SonyMobile", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=PortableAudio":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("PortableAudio", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=IncarNavigation":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("IncarNavigation", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=Playstation":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("Playstation", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=SemiconductorDevices":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("SemiconductorDevices", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=RecordingMediaEnergy":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("RecordingMediaEnergy", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=OtherProducts":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("OtherProducts", categoryRes)
        )}`;
      case "/view/ShowCategory.action?item=SonyProfessional":
        return `${ROUTES_LABEL.PRODUCTS}/${categoryReplace(
          fetchDisplayName("SonyProfessional", categoryRes)
        )}`;
      case "/datadownload/digitalAsset":
        return `${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.DIGITAL_ASSET}`;
      case "/datadownload/productData":
        return `${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.PRODUCT_DATA}`;
      case "/pricechange":
        return ROUTES_LABEL.PRICE_CHANGES;
      case "/order/ViewShoppingCart.action":
        return ROUTES_LABEL.ORDER_ENTRY;
      case "/product/ShowGradedStocks.action":
        return `${ROUTES_LABEL.ORDER_MANAGEMENT}/${ROUTES_LABEL.GRADED_STOCK}`;
      case "/forecasting/ShowProductGroupLanding.action":
        return ROUTES_LABEL.PRODUCT_LISTS;
      case "/order/ViewOrderReporting.action":
        return ROUTES_LABEL.REPORTING;
      case "/order/ViewOrderAudit.action":
        return ROUTES_LABEL.ORDER_CHANGE_REPORT;
      case "/order/ViewReportSubscriptionManagement.action":
        return ROUTES_LABEL.MANAGE_REPORTS;
      case "/sf/Quotations.action":
        return ROUTES_LABEL.MY_QUOTATIONS;
      case "PrimeSupportRegistration":
        return ROUTES_LABEL.PRIME_SUPPORT_REGISTRATION;
      case "/finance/ViewFinanceApplicationHomePage.action?&":
        return ROUTES_LABEL.HOME;
      case "/finance/ViewOpenItems.action?&":
        return ROUTES_LABEL.OPEN_ITEMS;
      case "/finance/FinanceApplication.action?financeFunction=2&":
        return ROUTES_LABEL.PAID_ITEMS;
      case "/finance/FinanceApplication.action?financeFunction=3&":
        return ROUTES_LABEL.PAYMENTS;
      case "/finance/FinanceApplication.action?financeFunction=6&":
        return ROUTES_LABEL.STATEMENTS;
      case "/finance/FinanceApplication.action?financeFunction=8&":
        return ROUTES_LABEL.DISPUTED_ITEMS;
      case "/finance/FinanceApplication.action?financeFunction=10&":
        return ROUTES_LABEL.DEBIT_NOTE_CREATION;
      case "/finance/FinanceApplication.action?financeFunction=9&":
        return ROUTES_LABEL.CREDIT_MANAGEMENT;
      case "/financeSearch/YourReferenceSearch.action":
        return ROUTES_LABEL.YOUR_REFERENCE_SEARCH;
      case "/visibility":
        return ROUTES_LABEL.PRODUCT_VISIBILITY;
      case "/opportunityvisibility":
        return ROUTES_LABEL.SONY_HOMEPAGE;
      case "/salesrepvisibility":
        return ROUTES_LABEL.SALES_REP_VISIBILITY;
      case "/security/ChangeDealerView.action":
        return ROUTES_LABEL.CHANGE_DEALER;
      case "/warranty/ViewExtendedWarranty.action?&":
        return ROUTES_LABEL.PREMIUM_SERVICES;
      case "ServiceAndSupportGeneralInformation":
        return `${ROUTES_LABEL.SERVCIE_SUPPORT}/${ROUTES_LABEL.GENERAL_INFORMATION}`;
      case "SERPPGeneralinformation":
        return `${ROUTES_LABEL.SERP}/${ROUTES_LABEL.GENERAL_INFORMATION}`;
      default:
        return "";
    }
  } else {
    return "";
  }
}

// For few menu items configuration to hide it or display is done at SFDC end as well,
// hence we need to check login response to add menu items visibility
function loginResConfigCheck(sectionName: any, data: any, bgName: any) {
  let criteria: any = {};
  switch (sectionName) {
    case "DNET Change Dealer Main":
      criteria = data?.attributes?.filter(
        (d: any) => d?.name === "changeDealer"
      );

      if (criteria) {
        if (criteria?.[0]?.value === "true") {
          return true;
        } else {
          return false;
        }
      } else return true;
    case "DNET System Admin":
      criteria = data?.attributes?.filter(
        (d: any) =>
          d?.name === "systemAdmin" || d?.name === "businessGroupAdmin"
      );
      if (criteria) {
        if (
          criteria?.[0]?.value === "true" ||
          criteria?.[1]?.value === "true"
        ) {
          return true;
        } else {
          return false;
        }
      } else return true;
    case "DNET Graded Stock":
      let bgValidation = data?.businessGroups?.filter((d: any) =>
        Array.isArray(bgName) ? bgName.includes(d?.name) : d?.name === bgName
      );
      criteria = bgValidation?.[0]?.attributes?.filter(
        (d: any) => d?.name === "gradedStock"
      );
      if (criteria) {
        if (criteria?.[0]?.value === "true") {
          return true;
        } else {
          return false;
        }
      } else return true;

    default:
      return true;
  }
}

function isAccessibleMenu(
  section: any,
  roles: any,
  bgName: any,
  locale: any,
  loginResponse: any,
  ecot3: any = ""
) {
  if (section?.siteSectionMetaData?.isEnabled === "true") {
    let roleList = [];
    if (section?.requiredPrincipals?.roles?.role) {
      if (Array.isArray(section?.requiredPrincipals?.roles?.role)) {
        roleList = section?.requiredPrincipals?.roles?.role;
      } else {
        roleList.push(section?.requiredPrincipals?.roles?.role);
      }
      let bgAvailable: any = [];
      let ruleAvailable: any = true;

      if (Array.isArray(section?.requiredPrincipals?.groups?.businessGroup)) {
        bgAvailable =
          section?.requiredPrincipals?.groups?.businessGroup?.filter(
            (bg: any) =>
              Array.isArray(bgName)
                ? bgName.includes(bg?.name)
                : bg?.name === bgName
          );

        let bgNameExists: any = Array.isArray(bgName)
          ? bgName.includes("ES100201") ||
            bgName.includes("ES100301") ||
            bgName.includes("PT100301") ||
            bgName.includes("PT100201")
          : bgName === "ES100201" ||
            bgName === "ES100301" ||
            bgName === "PT100201" ||
            bgName === "PT100301";
        if (
          bgAvailable?.length > 0 &&
          bgNameExists &&
          bgAvailable[0]?.rule?.expression?.includes("getSegmentationMap") &&
          bgAvailable[0]?.rule?.values === "true"
        ) {
          let ruleName: any = bgAvailable[0]?.rule?.expression?.replace(
            '""+getSegmentationMap().get("segmentation2").contains("',
            ""
          );
          ruleName = ruleName?.replace('")', "");
          let staffCheck = ecot3 === ruleName;
          if (
            !staffCheck &&
            bgAvailable[0]?.rule?.expression?.includes("getSegmentationMap")
          ) {
            ruleAvailable = false;
          } else {
            ruleAvailable = true;
          }
        } else if (
          bgAvailable?.length > 0 &&
          bgAvailable[0]?.rule?.expression?.includes(
            "hasVisibilityGroupAsString"
          ) &&
          bgAvailable[0]?.rule?.values === "true"
        ) {
          let ruleName: any = bgAvailable[0]?.rule?.expression?.replace(
            'hasVisibilityGroupAsString("',
            ""
          );
          ruleName = ruleName?.replace('")', "");
          let groupCheck = loginResponse?.groups?.indexOf(ruleName);
          if (
            groupCheck === -1 &&
            bgAvailable[0]?.rule?.expression?.includes(
              "hasVisibilityGroupAsString"
            )
          ) {
            ruleAvailable = false;
          } else {
            ruleAvailable = true;
          }
        }
      } else {
        bgAvailable = (
          Array.isArray(bgName)
            ? bgName.includes(
                section?.requiredPrincipals?.groups?.businessGroup?.name
              )
            : section?.requiredPrincipals?.groups?.businessGroup?.name ===
              bgName
        )
          ? section?.requiredPrincipals?.groups?.businessGroup
          : "";
      }
      const localeAvailable =
        section?.requiredPrincipals?.locales?.locale?.includes(locale);
      if (
        roleList.some((role: any) => roles?.includes(role)) &&
        bgAvailable?.length > 0 &&
        localeAvailable &&
        ruleAvailable
      ) {
        return true;
      }
    } else if (section?.requiredPrincipals?.roles === "") {
      let bgAvailable: any = [];
      let ruleAvailable: any = true;
      if (Array.isArray(section?.requiredPrincipals?.groups?.businessGroup)) {
        bgAvailable =
          section?.requiredPrincipals?.groups !== ""
            ? section?.requiredPrincipals?.groups?.businessGroup?.filter(
                (bg: any) =>
                  Array.isArray(bgName)
                    ? bgName.includes(bg?.name)
                    : bg?.name === bgName
              )
            : "";
        let bgNameExists: any = Array.isArray(bgName)
          ? bgName.includes("ES100201") ||
            bgName.includes("ES100301") ||
            bgName.includes("PT100301") ||
            bgName.includes("PT100201")
          : bgName === "ES100201" ||
            bgName === "ES100301" ||
            bgName === "PT100201" ||
            bgName === "PT100301";
        if (
          bgAvailable?.length > 0 &&
          bgNameExists &&
          bgAvailable[0]?.rule?.expression?.includes("getSegmentationMap") &&
          bgAvailable[0]?.rule?.values === "true"
        ) {
          let ruleName: any = bgAvailable[0]?.rule?.expression?.replace(
            '""+getSegmentationMap().get("segmentation2").contains("',
            ""
          );
          ruleName = ruleName?.replace('")', "");
          let staffCheck = ecot3 === ruleName;
          if (
            !staffCheck &&
            bgAvailable[0]?.rule?.expression?.includes("getSegmentationMap")
          ) {
            ruleAvailable = false;
          } else {
            ruleAvailable = true;
          }
        } else if (
          bgAvailable?.length > 0 &&
          bgAvailable[0]?.rule?.expression?.includes(
            "hasVisibilityGroupAsString"
          ) &&
          bgAvailable[0]?.rule?.values === "true"
        ) {
          let ruleName: any = bgAvailable[0]?.rule?.expression?.replace(
            'hasVisibilityGroupAsString("',
            ""
          );
          ruleName = ruleName?.replace('")', "");
          let groupCheck = loginResponse?.groups?.indexOf(ruleName);
          if (
            groupCheck === -1 &&
            bgAvailable[0]?.rule?.expression?.includes(
              "hasVisibilityGroupAsString"
            )
          ) {
            ruleAvailable = false;
          } else {
            ruleAvailable = true;
          }
        }
      } else {
        bgAvailable =
          section?.requiredPrincipals?.groups !== ""
            ? (
                Array.isArray(bgName)
                  ? bgName.includes(
                      section?.requiredPrincipals?.groups?.businessGroup?.name
                    )
                  : section?.requiredPrincipals?.groups?.businessGroup?.name ===
                    bgName
              )
              ? section?.requiredPrincipals?.groups?.businessGroup
              : ""
            : "";
      }

      const localeAvailable =
        section?.requiredPrincipals?.locales?.locale?.includes(locale);
      if (localeAvailable) {
        return bgAvailable !== "" && bgAvailable?.length > 0 && ruleAvailable
          ? true
          : // : bgAvailable === ""
            // ? true
            false;
      }
    }
  }
  return false;
}

export function checkStatus(data: any) {
  let status;
  let atpStatus = data?.product?.availabilityMap?.NOFG?.status;
  let centralAtpStatus =
    data?.product?.availabilityMap?.NOFG?.centralStockStatus;
  let salesAreaStr =
    data?.salesArea?.salesOrganisation +
    data?.salesArea?.distributionChannel +
    data?.salesArea?.salesDivision;

  let productHierarchyCode: any =
    data?.product?.detail?.thirdLevelHierarchyCode +
    data?.product?.detail?.productHierarchyCode;

  let atpStatusRed =
    atpStatus === PRODUCT_STATUS.PRODUCT_AVAILABILITY_UNKNOWN ||
    atpStatus === PRODUCT_STATUS.PRODUCT_SUBJECT_TO_MANUAL_ALLOCATION ||
    atpStatus === PRODUCT_STATUS.PRODUCT_UNAVAILABLE;
  if (
    atpStatusRed &&
    centralAtpStatus != null &&
    centralAtpStatus !== "" &&
    checkFromConfig(salesAreaStr, productHierarchyCode)
  ) {
    status = centralAtpStatus;
  } else if (
    atpStatus === PRODUCT_STATUS.PRODUCT_PRE_SALES ||
    ((data?.product?.detail?.salesStatus === PRODUCT_STATUS.PRODUCT_PRE_SALES ||
      data?.product?.detail?.salesStatus === "08") &&
      atpStatus === "")
  ) {
    status = PRODUCT_STATUS.PRODUCT_PRE_SALES;
  } else {
    status = atpStatus;
  }
  return status;
}

export const checkFromConfig = (
  salesAreaStr: any,
  productHierarchyCode: any
) => {
  let result: any = false;

  let salesAreaFromConfigList =
    CentralStockAvailabilityCheckerJson.enabledSalesAreas;
  let productHierarchyCodeFromConfigList =
    CentralStockAvailabilityCheckerJson.enabledHierarchyCodes;

  if (
    salesAreaFromConfigList &&
    salesAreaFromConfigList?.findIndex(
      (salesArea: any) => salesArea == salesAreaStr
    ) != -1
  ) {
    productHierarchyCodeFromConfigList?.forEach((pattern: any) => {
      if (productHierarchyCode.includes(pattern)) {
        result = true;
      }
    });
  }

  return result;
};
export function getAvailabilityDetails(details: any) {
  const status = checkStatus(details);
  let colorCode: any = [];
  let titleText: any = "";
  let availabilityMessage: any = "";
  switch (status) {
    case PRODUCT_STATUS.PRODUCT_AVAILABILITY:
      colorCode = [GREEN, GRAY, GRAY];
      titleText = "dnet.current_stock.text";
      availabilityMessage = "";
      break;
    case PRODUCT_STATUS.PRODUCT_AVAILABILITY_ON_KNOWN_DATE:
      colorCode = [GRAY, YELLOW, GRAY];
      titleText = "dnet.next_available_stock.text";
      availabilityMessage = checkIncomingDate(details);
      break;
    case PRODUCT_STATUS.PRODUCT_UNAVAILABLE:
      colorCode = [GRAY, GRAY, RED];
      titleText = "dnet.contact_sony_for_avb.text";
      availabilityMessage = "dnet.contact_sony.text";
      break;
    case PRODUCT_STATUS.PRODUCT_SUBJECT_TO_MANUAL_ALLOCATION:
      colorCode = [GRAY, GRAY, RED];
      titleText = "dnet.contact_sony_for_avb.text";
      availabilityMessage = "dnet.contact_sony.text";
      break;
    case PRODUCT_STATUS.PRODUCT_AVAILABLE_BUT_SUBJECT_TO_QUOTA:
      colorCode = [GREEN, GRAY, GRAY];
      titleText = "dnet.current_stock.text";
      availabilityMessage = "";
      break;
    case PRODUCT_STATUS.PRODUCT_AVAILABLE_ON_KNOWN_DATE_AND_SUBJECT_TO_QUOTA:
      colorCode = [GRAY, YELLOW, GRAY];
      titleText = "dnet.next_available_stock.text";
      availabilityMessage = ""; //TODO - DNETOrderEntrySSTAvailabilityCellContactSonyText - no value found
      break;
    case PRODUCT_STATUS.PRODUCT_PRE_SALES:
      colorCode = [BLUE, BLUE, BLUE];
      titleText = "dnet.new_launch.text";
      break;
    case PRODUCT_STATUS.PRODUCT_AVAILABILITY_UNKNOWN:
      colorCode = [GRAY, GRAY, RED];
      break;
    default:
      colorCode = [GRAY, GRAY, GRAY];
  }
  return {
    colorCode,
    titleText,
    availabilityMessage,
  };
}

function checkIncomingDate(data: any) {
  let atpStatus = data?.product?.availabilityMap?.NOFG?.status;
  let atpIncomingDate = data?.product?.availabilityMap?.date; //TODO: check the date logic, as per the data it should be under NOFG
  let centralAtpIncomingDate = data?.product?.availabilityMap?.centralStockDate;
  let atpStatusRed =
    atpStatus === PRODUCT_STATUS.PRODUCT_AVAILABILITY_UNKNOWN ||
    atpStatus === PRODUCT_STATUS.PRODUCT_SUBJECT_TO_MANUAL_ALLOCATION ||
    atpStatus === PRODUCT_STATUS.PRODUCT_UNAVAILABLE;
  let incomingDate = "";
  if (
    atpStatusRed &&
    centralAtpIncomingDate != null &&
    !_.isEmpty(centralAtpIncomingDate)
  ) {
    incomingDate = centralAtpIncomingDate;
  } else {
    incomingDate = atpIncomingDate;
  }
  return incomingDate;
}

export function categoryReplace(data: any) {
  return data?.replaceAll(/[^A-Za-z0-9]/g, "");
}

export const mimeTypeImageMapping = (type: any) => {
  switch (type) {
    case "application/pdf":
      return "pdf_big.gif";
    case "image/jpeg":
      return "jpg_big.gif";
    case "application/octet-stream":
      return "other_big.gif";
    case "audio/x-wav":
      return "wav_big.gif";
    case "application/msword":
      return "doc_big.gif";
    case "application/mspowerpoint":
      return "ppt_big.gif";
    case "application/msexcel":
      return "xls_big.gif";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xls_big.gif";
    case "video/quicktime":
      return "quicktime_icon_big.gif";
    case "video/mpeg":
      return "video_mpeg_icon_big.gif";
    case "application/zip":
      return "zip_big.gif";
    case "audio/mpeg":
      return "audio_mpeg_icon_big.gif";
    default:
      return "";
  }
};

export const formatSalesArea = (salesAreaString: string) => {
  if (salesAreaString && salesAreaString.length >= 8) {
    let salesOrganisation = salesAreaString.substring(0, 4);
    let distributionChannel = salesAreaString.substring(4, 6);
    let salesDivision = salesAreaString.substring(6);

    let salesArea: any = {
      salesOrganisation,
      distributionChannel,
      salesDivision,
    };
    return salesArea;
  }
  return null;
};

export const isWeekend = (date: any) => {
  const day = new Date(date).getDay();
  return day === 0 || day === 6; //check for Saturday and Sunday
};

export const shouldDisableDateFunc = (
  date: any,
  basicCalender: any,
  calendarDetails: any,
  reporting: any = false
) => {
  if (basicCalender) {
    // if (reporting) {
    //   let currentDate = new Date()
    //   return new Date(date).getTime() >= currentDate.getTime() && isWeekend(date);
    // }
    return isWeekend(date);
  }
  // logic to show fetched dates
  let yearObject = calendarDetails?.filter(
    (item: any) => item?.year === date?.year()?.toString()
  );
  if (yearObject?.length > 0) {
    let monthString = yearObject[0]?.months?.[date?.month()];
    let day = monthString?.charAt(date.date() - 1) === "0";
    return isWeekend(date) || day;
  }
  return isWeekend(date);
};

export const isJsonString = (str: any) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const fetchWarrantyProducts = (prodList: any) => {
  const updatedProdList = prodList.map((productItem: any) => {
    const { product } = productItem;

    if (product && product?.warranties && Array.isArray(product?.warranties)) {
      const updatedWarranties = product?.warranties?.map(
        (warrantyString: any) => {
          if (isJsonString(warrantyString)) {
            return JSON.parse(warrantyString);
          }
          return warrantyString;
        }
      );
      return {
        ...productItem,
        product: {
          ...productItem.product,
          warranties: updatedWarranties,
        },
      };
    }
    return productItem;
  });
  return updatedProdList;
};

export const amendPlus = (
  minQty: any,
  deliveryUnit: any,
  minDeliveryQuantity: any,
  maxQty: any
) => {
  const result = calcResultMOQIncrease2(
    maxQty,
    minQty,
    deliveryUnit,
    minDeliveryQuantity,
    "plus"
  );
  if (result > maxQty) return maxQty;
  else return result;
};

export const calcResultMOQIncrease2 = (
  qty: any,
  minQty: any,
  deliveryUnit: any,
  minDeliveryQuantity: any,
  pType: any
) => {
  const precious = qty;
  const minLocalQuantity = Math.max(minQty, minDeliveryQuantity);

  if (deliveryUnit <= 0) deliveryUnit = 1;

  if (isNaN(precious) || precious < 0)
    return pType == "plus" ? minLocalQuantity : 0;
  else if (pType == "plus" && precious < minLocalQuantity)
    return minLocalQuantity;
  else if (pType == "minus" && precious < minLocalQuantity) return 0;
  else if (
    pType == "minus" &&
    precious > minLocalQuantity &&
    precious - deliveryUnit < minLocalQuantity
  )
    return minLocalQuantity;

  const restValue = (precious - minLocalQuantity) % deliveryUnit;
  if (restValue != 0 && pType == "plus")
    return precious - restValue + deliveryUnit;
  else if (restValue != 0 && pType == "minus") return precious - restValue;

  let result: any = "";

  if (pType == "plus") {
    result = Math.round(precious) + Math.round(deliveryUnit);
  } else if (pType == "minus") {
    result = Math.round(precious) - Math.round(deliveryUnit);
  }

  if (pType == "plus" && result < minLocalQuantity) result = minLocalQuantity;
  else if (pType == "minus" && result < minLocalQuantity) result = 0;

  return result;
};

export const minus2 = (
  qty: any,
  minQty: any,
  deliveryUnit: any,
  minDeliveryQuantity: any
) => {
  const result = calcResultMOQIncrease2(
    qty,
    minQty,
    deliveryUnit,
    minDeliveryQuantity,
    "minus"
  );
  return result;
};

export const plus2 = (
  qty: any,
  minQty: any,
  deliveryUnit: any,
  minDeliveryQuantity: any
) => {
  const result = calcResultMOQIncrease2(
    qty,
    minQty,
    deliveryUnit,
    minDeliveryQuantity,
    "plus"
  );
  return result;
};
export function unzip(payload: any) {
  if (payload?.length > 0 && payload != undefined && payload != null) {
    const compressedData = Buffer.from(payload, "base64");
    const decompressedData = pako.inflate(compressedData, { to: "string" });
    return decompressedData ? JSON.parse(decompressedData) : [];
  } else {
    return payload;
  }
}

export const getTitle = () => {
  if (process.env.REACT_APP_ENV === "prod") return "1";
  else return "1 Preview";
};
